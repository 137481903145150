<template>
  <el-dialog width="80%" :title="!dataForm.id ? '新增角色' : '角色信息编辑'" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :inline="true" :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="beforeSubmit()"
      label-width="120px">
      <el-tabs type="border-card">
        <el-tab-pane label="阿拉伯语">
          <el-form-item label="头像">
            <el-upload class="avatar-uploader" :action="ossUploadAvatarUrl" :show-file-list="false"
              :on-success="handleArAvatarSuccess" :before-upload="beforeAvatarUpload">
              <img v-if="dataForm.ar.avatar" :src="dataForm.ar.avatar" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

          <el-form-item v-if="dataForm.id" label=" ">
            <ul>
              <li>
                uid: {{ this.dataForm.uid }}
              </li>
              <li>
                上一次编辑时间: {{ this.dataForm.ar.updateTime }}
              </li>
              <li>
                上一次编辑人: {{ this.dataForm.ar.updatePersonName }}
              </li>
            </ul>

          </el-form-item>

          <h3>1. 基础信息</h3>
          <el-form-item label="姓名" prop="ar.nickname">
            <el-input v-model="dataForm.ar.nickname" placeholder="姓名"></el-input>
          </el-form-item>

          <el-form-item label="所属IP" prop="virtualIps">

            <el-select v-model="dataForm.virtualIpIds" clearable multiple placeholder="所属IP" filterable>
              <el-option v-for="item in allVirtualIpList" :key="item.id"
                :label="item.description + '（' + item.langIp.ar + '）'" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="性别" prop="gender">
            <el-select v-model="dataForm.gender" clearable placeholder="性别">
              <el-option v-for="item in genderList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="职业" prop="ar.occupation">
            <el-input v-model="dataForm.ar.occupation" placeholder="职业"></el-input>
          </el-form-item>

          <el-form-item label="状态" prop="virtualStatus">
            <el-select v-model="dataForm.virtualStatus" clearable placeholder="状态">
              <el-option v-for="item in virtualStatusList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="模型" prop="talkMode">
            <el-select v-model="dataForm.talkMode" clearable placeholder="模型" filterable>
              <el-option v-for="item in talkModeList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="个性签名" prop="ar.intro">
            <el-input type="textarea" rows="3" v-model="dataForm.ar.intro" style="width: 30vw;"></el-input>
          </el-form-item>

          <div>
            <el-form-item label="首句背景介绍" prop="ar.enabledPushText">
              <el-radio-group v-model="dataForm.ar.enabledPushText">
                <el-radio :label="1">开启</el-radio>
                <el-radio :label="0">关闭</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item prop="ar.introduce" style="margin-left: 2vw;">
              <el-input type="textarea" rows="3" style="width: 40vw;" v-if="dataForm.ar.enabledPushText"
                v-model="dataForm.ar.introduce"></el-input>
            </el-form-item>
          </div>

          <div>
            <el-form-item label="是否开启声音" prop="voiceStatus">
              <el-radio-group v-model="dataForm.voiceStatus">
                <el-radio :label="1">开启</el-radio>
                <el-radio :label="0">关闭</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="声音" prop="voiceId" v-if="dataForm.voiceStatus">
              <el-select v-model="dataForm.voiceId" clearable placeholder="声音" filterable>
                <el-option v-for="item in voiceList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <el-form-item label="灵感功能" prop="inspirationFlag">
            <el-radio-group v-model="dataForm.inspirationFlag">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>

          <div>
            <el-form-item label="图片功能" prop="imgStatus">
              <el-radio-group v-model="dataForm.imgStatus">
                <el-radio :label="1">开启</el-radio>
                <el-radio :label="0">关闭</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="图片ID" prop="imgId" v-if="dataForm.imgStatus">
              <el-input v-model="dataForm.imgId" placeholder="图片ID" type="textarea" rows="1"
                style="width: 20vw;"></el-input>
            </el-form-item>

            <el-form-item label="图片Prompt" prop="imgPrompt" v-if="dataForm.imgStatus">
              <el-input type="textarea" rows="4" style="width: 20vw;" v-model="dataForm.imgPrompt"
                placeholder="图片Prompt"></el-input>
            </el-form-item>

            <el-form-item label="首图" prop="firstPhoto" v-if="dataForm.imgStatus">
              <el-upload class="avatar-uploader" :action="ossUploadFirPicUrl" :show-file-list="false"
                :on-success="handleFirPicSuccess">
                <img v-if="dataForm.firstPhoto" :src="dataForm.firstPhoto" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </div>

          <h3>2. prompt类型选择</h3>
          <el-form-item prop="en.promptType" style="margin:2vh 2vw;">
            <el-radio v-model="dataForm.ar.promptType" :label="1">自定义prompt</el-radio>
            <el-radio v-model="dataForm.ar.promptType" :label="2">模块化prompt</el-radio>
          </el-form-item>

          <el-form-item v-if="dataForm.ar.promptType === 1" style="width: 100%" label="自定义prompt" prop="ar.prompt">
            <el-input rows="6" style="width: 600px" type="textarea" v-model="dataForm.ar.prompt"></el-input>
          </el-form-item>


          <div v-if="dataForm.ar.promptType === 2">
            <el-form-item label="自我称呼" prop="ar.promptDefine.nameOfSelf">
              <el-input v-model="dataForm.ar.promptDefine.nameOfSelf" placeholder="自我称呼"></el-input>
            </el-form-item>
            <el-form-item label="用户称呼" prop="ar.promptDefine.nameOfUser">
              <el-input v-model="dataForm.ar.promptDefine.nameOfUser" placeholder="用户称呼"></el-input>
            </el-form-item>
            <el-form-item label="外貌形象" prop="ar.promptDefine.appearance">
              <el-input v-model="dataForm.ar.promptDefine.appearance" placeholder="外貌形象"></el-input>
            </el-form-item>
            <el-form-item label="年龄描述" prop="ar.promptDefine.ageDescription">
              <el-input v-model="dataForm.ar.promptDefine.ageDescription" placeholder="年龄描述"></el-input>
            </el-form-item>
            <el-form-item label="说话风格" prop="ar.promptDefine.talkingStyle">
              <el-input v-model="dataForm.ar.promptDefine.talkingStyle" placeholder="说话风格"></el-input>
            </el-form-item>
            <el-form-item label="其他补充" prop="ar.promptDefine.otherRoleDefine">
              <el-input type="textarea" v-model="dataForm.ar.promptDefine.otherRoleDefine" placeholder="其他补充"></el-input>
            </el-form-item>

            <h3>3. 对话场景</h3>

            <el-form-item label="对话关系" prop="ar.promptDefine.conversationRelationship">
              <el-input v-model="dataForm.ar.promptDefine.conversationRelationship" placeholder="对话关系"></el-input>
            </el-form-item>
            <el-form-item label="对话方式" prop="ar.promptDefine.conversationMode">
              <el-input v-model="dataForm.ar.promptDefine.conversationMode" placeholder="对话方式"></el-input>
            </el-form-item>
            <el-form-item label="对话目的" prop="ar.promptDefine.conversationGoal">
              <el-input v-model="dataForm.ar.promptDefine.conversationGoal" placeholder="对话目的"></el-input>
            </el-form-item>
            <el-form-item label="回复字数" prop="ar.promptDefine.responseWords">
              <el-input v-model="dataForm.ar.promptDefine.responseWords" placeholder="回复字数"></el-input>
            </el-form-item>
            <el-form-item label="其他补充" prop="ar.promptDefine.otherConversationDefine">
              <el-input v-model="dataForm.ar.promptDefine.otherConversationDefine" placeholder="其他补充"></el-input>
            </el-form-item>

            <h3>4. 其它补充信息（选填内容）</h3>

            <el-form-item prop="ar.promptDefine.otherInformation" style="margin:2vh 3vw;">
              <el-input type="textarea" v-model="dataForm.ar.promptDefine.otherInformation"
                style=" width: 40vw;"></el-input>
            </el-form-item>
          </div>
        </el-tab-pane>

        <el-tab-pane label="英语">
          <el-form-item label="头像">
            <el-upload class="avatar-uploader" :action="ossUploadAvatarUrl" :show-file-list="false"
              :on-success="handleEnAvatarSuccess" :before-upload="beforeAvatarUpload">
              <img v-if="dataForm.en.avatar" :src="dataForm.en.avatar" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

          <el-form-item v-if="dataForm.id" label=" ">
            <ul>
              <li>
                uid: {{ this.dataForm.uid }}
              </li>
              <li>
                上一次编辑时间: {{ this.dataForm.ar.updateTime }}
              </li>
              <li>
                上一次编辑人: {{ this.dataForm.ar.updatePersonName }}
              </li>
            </ul>

          </el-form-item>


          <h3>1. 基础信息</h3>
          <el-form-item label="姓名" prop="en.nickname">
            <el-input v-model="dataForm.en.nickname" placeholder="姓名"></el-input>
          </el-form-item>

          <el-form-item label="所属IP" prop="virtualIps">

            <el-select v-model="dataForm.virtualIpIds" clearable multiple placeholder="所属IP" filterable>
              <el-option v-for="item in allVirtualIpList" :key="item.id"
                :label="item.description + '（' + item.langIp.en + '）'" :value="item.id">
              </el-option>
            </el-select>

          </el-form-item>

          <el-form-item label="性别" prop="gender">
            <el-select v-model="dataForm.gender" clearable placeholder="性别">
              <el-option v-for="item in genderList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="职业" prop="ar.occupation">
            <el-input v-model="dataForm.en.occupation" placeholder="职业"></el-input>
          </el-form-item>

          <el-form-item label="状态" prop="virtualStatus">
            <el-select v-model="dataForm.virtualStatus" clearable placeholder="状态">
              <el-option v-for="item in virtualStatusList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="模型" prop="talkMode">
            <el-select v-model="dataForm.talkMode" clearable placeholder="模型" filterable>
              <el-option v-for="item in talkModeList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="个性签名" prop="en.intro">
            <el-input type="textarea" rows="3" v-model="dataForm.en.intro" style="width: 30vw;"></el-input>
          </el-form-item>

          <div>
            <el-form-item label="首句背景介绍" prop="en.enabledPushText">
              <el-radio-group v-model="dataForm.en.enabledPushText">
                <el-radio :label="1">开启</el-radio>
                <el-radio :label="0">关闭</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item prop="en.introduce">
              <el-input type="textarea" rows="3" style="width: 40vw;" v-if="dataForm.en.enabledPushText"
                v-model="dataForm.en.introduce"></el-input>
            </el-form-item>
          </div>

          <div>
            <el-form-item label="是否开启声音" prop="voiceStatus">
              <el-radio-group v-model="dataForm.voiceStatus">
                <el-radio :label="1">开启</el-radio>
                <el-radio :label="0">关闭</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="声音" prop="voiceId" v-if="dataForm.voiceStatus">
              <el-select v-model="dataForm.voiceId" clearable placeholder="声音" filterable>
                <el-option v-for="item in voiceList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <el-form-item label="灵感功能" prop="inspirationFlag">
            <el-radio-group v-model="dataForm.inspirationFlag">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>

          <div>
            <el-form-item label="图片功能" prop="imgStatus">
              <el-radio-group v-model="dataForm.imgStatus">
                <el-radio :label="1">开启</el-radio>
                <el-radio :label="0">关闭</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="图片ID" prop="imgId" v-if="dataForm.imgStatus">
              <el-input v-model="dataForm.imgId" placeholder="图片ID" type="textarea" rows="1"
                style="width: 20vw;"></el-input>
            </el-form-item>

            <el-form-item label="图片Prompt" prop="imgPrompt" v-if="dataForm.imgStatus">
              <el-input type="textarea" rows="4" style="width: 20vw;" v-model="dataForm.imgPrompt"
                placeholder="图片Prompt"></el-input>
            </el-form-item>

            <el-form-item label="首图" prop="firstPhoto" v-if="dataForm.imgStatus">
              <el-upload class="avatar-uploader" :action="ossUploadFirPicUrl" :show-file-list="false"
                :on-success="handleFirPicSuccess">
                <img v-if="dataForm.firstPhoto" :src="dataForm.firstPhoto" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </div>

          <h3>2. prompt类型选择</h3>
          <el-form-item prop="en.promptType" style="margin:2vh 2vw;">
            <el-radio v-model="dataForm.en.promptType" :label="1">自定义prompt</el-radio>
            <el-radio v-model="dataForm.en.promptType" :label="2">模块化prompt</el-radio>
          </el-form-item>

          <el-form-item v-if="dataForm.en.promptType === 1" style="width: 100%" label="自定义prompt" prop="en.prompt">
            <el-input rows="6" style="width: 600px" type="textarea" v-model="dataForm.en.prompt"></el-input>
          </el-form-item>


          <div v-if="dataForm.en.promptType === 2">
            <h3>3. 角色定义</h3>
            <el-form-item label="自我称呼" prop="en.promptDefine.nameOfSelf">
              <el-input v-model="dataForm.en.promptDefine.nameOfSelf" placeholder="自我称呼"></el-input>
            </el-form-item>
            <el-form-item label="用户称呼" prop="en.promptDefine.nameOfUser">
              <el-input v-model="dataForm.en.promptDefine.nameOfUser" placeholder="用户称呼"></el-input>
            </el-form-item>
            <el-form-item label="职业" prop="en.promptDefine.occupation">
              <el-input v-model="dataForm.en.promptDefine.occupation" placeholder="职业"></el-input>
            </el-form-item>
            <el-form-item label="外貌形象" prop="en.promptDefine.appearance">
              <el-input v-model="dataForm.en.promptDefine.appearance" placeholder="外貌形象"></el-input>
            </el-form-item>
            <el-form-item label="年龄描述" prop="en.promptDefine.ageDescription">
              <el-input v-model="dataForm.en.promptDefine.ageDescription" placeholder="年龄描述"></el-input>
            </el-form-item>
            <el-form-item label="说话风格" prop="en.promptDefine.talkingStyle">
              <el-input v-model="dataForm.en.promptDefine.talkingStyle" placeholder="说话风格"></el-input>
            </el-form-item>
            <el-form-item label="其他补充" prop="en.promptDefine.otherRoleDefine">
              <el-input v-model="dataForm.en.promptDefine.otherRoleDefine" placeholder="其他补充"></el-input>
            </el-form-item>

            <h3>3. 对话场景</h3>

            <el-form-item label="对话关系" prop="en.promptDefine.conversationRelationship">
              <el-input v-model="dataForm.en.promptDefine.conversationRelationship" placeholder="对话关系"></el-input>
            </el-form-item>
            <el-form-item label="对话方式" prop="en.promptDefine.conversationMode">
              <el-input v-model="dataForm.en.promptDefine.conversationMode" placeholder="对话方式"></el-input>
            </el-form-item>
            <el-form-item label="对话目的" prop="en.promptDefine.conversationGoal">
              <el-input v-model="dataForm.en.promptDefine.conversationGoal" placeholder="对话目的"></el-input>
            </el-form-item>
            <el-form-item label="回复字数" prop="en.promptDefine.responseWords">
              <el-input v-model="dataForm.en.promptDefine.responseWords" placeholder="回复字数"></el-input>
            </el-form-item>
            <el-form-item label="其他补充" prop="en.promptDefine.otherConversationDefine">
              <el-input v-model="dataForm.en.promptDefine.otherConversationDefine" placeholder="其他补充"></el-input>
            </el-form-item>

            <h3>4. 其它补充信息（选填内容）</h3>

            <el-form-item prop="en.promptDefine.otherInformation" style="margin:2vh 3vw;">
              <el-input type="textarea" v-model="dataForm.en.promptDefine.otherInformation"
                style=" width: 40vw;"></el-input>
            </el-form-item>
          </div>
        </el-tab-pane>

      </el-tabs>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :loading="btnLoading" @click="visible = false">取消</el-button>
      <el-button :loading="btnLoading" type="primary" @click="beforeSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { genderList } from "@/views/common/commonOpt";

const sourceDataSubForm = {
  nickname: '',
  avatar: '',
  avatarExpand: '',
  occupation: '',
  virtualIp: '',
  virtualIpIds: '',
  intro: '',
  introduce: '',
  enabledPushText: 0,
  prompt: '',
  promptType: 1,
  promptDefine: {
    nameOfSelf: '',
    nameOfUser: '',
    occupation: '',
    appearance: '',
    ageDescription: '',
    talkingStyle: '',
    otherRoleDefine: '',
    conversationRelationship: '',
    conversationMode: '',
    conversationGoal: '',
    responseWords: '',
    otherConversationDefine: '',
    otherInformation: ''
  },
  updateTime: '',
  updatePersonName: '',
  sourceNickname: '',
  virtualStatus: '',
  gender: '',
  talkMode: '',
  inspirationFlag: 0,
  voiceStatus: 0,
  voiceId: '',
  imgStatus: 0,
  imgId: '',
  imgPrompt: '',
  firstPhoto: '',
}
const sourceDataForm = {
  uid: 0,
  virtualStatus: '',
  gender: '',
  talkMode: '',
  virtualIpIds: '',
  inspirationFlag: 0,
  voiceStatus: 0,
  voiceId: '',
  imgStatus: 0,
  imgId: '',
  imgPrompt: '',
  firstPhoto: '',
  ar: JSON.parse(JSON.stringify(sourceDataSubForm)),
  en: JSON.parse(JSON.stringify(sourceDataSubForm))
}
export default {
  data() {
    return {
      btnLoading: false,
      ossUploadAvatarUrl: "",
      ossUploadFirPicUrl: "",
      visible: false,
      roleList: [],
      allVirtualIpList: [],
      voiceList: [],
      dataForm: JSON.parse(JSON.stringify(sourceDataForm)),
      dataRule: {
        'ar.nickname': [
          { required: true, message: '用户名不能为空', trigger: 'blur' }
        ],
        'en.nickname': [
          { required: true, message: '用户名不能为空', trigger: 'blur' }
        ],
        'ar.prompt': [
          { required: true, message: '自定义Prompt不能为空', trigger: 'blur' }
        ],
        'en.prompt': [
          { required: true, message: '自定义Prompt不能为空', trigger: 'blur' }
        ],
        'gender': [
          { required: true, message: '性别不能为空', trigger: 'blur' }
        ],
        'virtualStatus': [
          { required: true, message: '状态不能为空', trigger: 'blur' }
        ],
        'talkMode': [
          { required: true, message: '模型不能为空', trigger: 'blur' }
        ],
        'imgPrompt': [
          { required: true, message: '图片Prompt不能为空', trigger: 'blur' }
        ],
        'imgId': [
          { required: true, message: '图片Id不能为空', trigger: 'blur' }
        ],
      },
      genderList: genderList,
      virtualStatusList: [
        {
          value: 0,
          label: '正常'
        },
        {
          value: 1,
          label: '下线'
        },
        {
          value: 2,
          label: '死亡'
        }
      ],
      talkModeList: [],
    }
  },
  async created() {
    this.ossUploadAvatarUrl = this.$http.adornUrl(
      `/sys/oss/upload/avatarCompress?token=${this.$cookie.get("token")}`
    );
    this.ossUploadFirPicUrl = this.$http.adornUrl(
      `/sys/oss/upload/firstPhotoCompress?token=${this.$cookie.get("token")}`
    );
    this.allVirtualIpList = await this.$store.dispatch("GetVirtualIp", {});
    this.getAllTalkMode()
    this.getAllVoiceList()
  },
  methods: {
    init(id) {
      this.dataForm = JSON.parse(JSON.stringify(sourceDataForm))
      this.dataForm.id = id || 0
      if (this.dataForm.id === 0) {
        // 新增模式
        this.visible = true
        return;
      }

      if (this.$refs['dataForm']) this.$refs['dataForm'].resetFields()

      this.$http({
        url: this.$http.adornUrl(`/admin/virtual/info`),
        method: 'get',
        params: this.$http.adornParams({ uid: this.dataForm.id })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.visible = true
          this.dataForm.uid = data.result.uid

          if (data.result.ar) {
            this.dataForm.ar.avatar = data.result.ar.avatar
            this.dataForm.ar.userName = data.result.ar.username
            this.dataForm.ar.nickname = data.result.ar.nickname
            this.dataForm.gender = data.result.ar.gender
            this.dataForm.ar.occupation = data.result.ar.occupation
            this.dataForm.virtualStatus = data.result.ar.virtualStatus
            this.dataForm.ar.virtualIp = data.result.ar.virtualIp
            this.dataForm.ar.intro = data.result.ar.intro
            this.dataForm.ar.introduce = data.result.ar.introduce
            this.dataForm.ar.enabledPushText = data.result.ar.enabledPushText
            this.dataForm.ar.promptDefine = data.result.ar.promptDefine
            this.dataForm.ar.updateTime = data.result.ar.updateTime
            this.dataForm.ar.updatePersonName = data.result.ar.updatePersonName
            this.dataForm.ar.sourceNickname = data.result.ar.nickname
            this.dataForm.ar.virtualIpId = data.result.ar.virtualIpId
            this.dataForm.virtualIpIds = data.result.ar.virtualIpIds
            this.dataForm.ar.prompt = data.result.ar.prompt
            this.dataForm.ar.promptType = data.result.ar.promptType
            this.dataForm.talkMode = data.result.ar.talkMode
            this.dataForm.inspirationFlag = data.result.ar.inspirationFlag
            this.dataForm.voiceStatus = data.result.ar.voiceStatus !== null ? data.result.ar.voiceStatus : 0
            this.dataForm.voiceId = data.result.ar.voiceId
            this.dataForm.imgStatus = data.result.ar.imgStatus !== null ? data.result.ar.imgStatus : 0
            this.dataForm.imgId = data.result.ar.imgId
            this.dataForm.imgPrompt = data.result.ar.imgPrompt
            this.dataForm.firstPhoto = data.result.ar.firstPhoto
          }
          if (data.result.en) {
            this.dataForm.en.avatar = data.result.en.avatar
            this.dataForm.en.userName = data.result.en.username
            this.dataForm.en.nickname = data.result.en.nickname
            this.dataForm.gender = data.result.en.gender
            this.dataForm.en.occupation = data.result.en.occupation
            this.dataForm.virtualStatus = data.result.en.virtualStatus
            this.dataForm.en.virtualIp = data.result.en.virtualIp
            this.dataForm.en.intro = data.result.en.intro
            this.dataForm.en.introduce = data.result.en.introduce
            this.dataForm.en.enabledPushText = data.result.en.enabledPushText
            this.dataForm.en.promptDefine = data.result.en.promptDefine
            this.dataForm.en.updateTime = data.result.en.updateTime
            this.dataForm.en.updatePersonName = data.result.en.updatePersonName
            this.dataForm.en.sourceNickname = data.result.en.nickname
            this.dataForm.en.virtualIpId = data.result.en.virtualIpId
            this.dataForm.virtualIpIds = data.result.en.virtualIpIds
            this.dataForm.en.prompt = data.result.en.prompt
            this.dataForm.en.promptType = data.result.en.promptType
            this.dataForm.talkMode = data.result.en.talkMode
            this.dataForm.inspirationFlag = data.result.en.inspirationFlag
            this.dataForm.voiceStatus = data.result.en.voiceStatus !== null ? data.result.en.voiceStatus : 0
            this.dataForm.voiceId = data.result.en.voiceId
            this.dataForm.imgStatus = data.result.en.imgStatus !== null ? data.result.en.imgStatus : 0
            this.dataForm.imgId = data.result.en.imgId
            this.dataForm.imgPrompt = data.result.en.imgPrompt
            this.dataForm.firstPhoto = data.result.en.firstPhoto
          }
        }
      })
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 或者png 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    handleArAvatarSuccess(res, file) {
      if (res && res.code === 0) {
        this.dataForm.ar.avatar = res.url
        this.dataForm.ar.avatarExpand = res.fixedUrl
      } else {
        this.$message.error('上传头像图片失败:' + res.msg)
      }
    },
    handleEnAvatarSuccess(res, file) {
      if (res && res.code === 0) {
        this.dataForm.en.avatar = res.url
        this.dataForm.en.avatarExpand = res.fixedUrl
      } else {
        this.$message.error('上传头像图片失败:' + res.msg)
      }
    },
    handleFirPicSuccess(res, file) {
      if (res && res.code === 0) {
        this.dataForm.firstPhoto = res.url
      } else {
        this.$message.error('上传首图失败:' + res.msg)
      }
    },

    beforeSubmit() {
      // console.log("dataForm =>", this.dataForm)
      if (this.dataForm.id) {
        this.$confirm('虚拟角色【' + this.dataForm.ar.sourceNickname + '】个人信息已被修改，本次调整将影响角色的全站反馈影响较大，当前修改是否已确认无误', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.dataFormSubmit();
        })
      } else {
        this.dataFormSubmit();
      }
    },
    async getAllTalkMode() {
      // 获取所有类目
      await this.$http({
        url: this.$http.adornUrl(`/admin/mode/list`),
        method: 'post'
      }).then(({ data }) => {
        if (data && data.code === 0) {
          data.result.forEach(item => {
            this.talkModeList.push({
              label: item,
              value: item,
            })
          })
        } else {
          this.$message.error(data.msg)
        }
      })
    },

    async getAllVoiceList() {
      // 获取所有类目
      await this.$http({
        url: this.$http.adornUrl(`/admin/virtual/getVoice`),
        method: 'get'
      }).then(({ data }) => {
        data.forEach(item => {
          this.voiceList.push({
            label: item.name,
            value: item.voice_id,
          })
        })
      })
    },
    // 表单提交
    dataFormSubmit() {
      this.dataForm.ar.gender = this.dataForm.gender
      this.dataForm.en.gender = this.dataForm.gender
      this.dataForm.ar.virtualStatus = this.dataForm.virtualStatus
      this.dataForm.en.virtualStatus = this.dataForm.virtualStatus
      this.dataForm.ar.talkMode = this.dataForm.talkMode
      this.dataForm.en.talkMode = this.dataForm.talkMode
      this.dataForm.ar.virtualIpIds = this.dataForm.virtualIpIds
      this.dataForm.en.virtualIpIds = this.dataForm.virtualIpIds
      this.dataForm.ar.inspirationFlag = this.dataForm.inspirationFlag
      this.dataForm.en.inspirationFlag = this.dataForm.inspirationFlag

      this.dataForm.ar.voiceStatus = this.dataForm.voiceStatus
      this.dataForm.en.voiceStatus = this.dataForm.voiceStatus
      this.dataForm.ar.voiceId = this.dataForm.voiceId
      this.dataForm.en.voiceId = this.dataForm.voiceId

      this.dataForm.ar.imgStatus = this.dataForm.imgStatus
      this.dataForm.en.imgStatus = this.dataForm.imgStatus
      this.dataForm.ar.imgId = this.dataForm.imgId
      this.dataForm.en.imgId = this.dataForm.imgId
      this.dataForm.ar.imgPrompt = this.dataForm.imgPrompt
      this.dataForm.en.imgPrompt = this.dataForm.imgPrompt
      this.dataForm.ar.firstPhoto = this.dataForm.firstPhoto
      this.dataForm.en.firstPhoto = this.dataForm.firstPhoto
      // console.log('this.dataForm', this.dataForm)

      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          // console.log('this.dataForm', this.dataForm)
          this.btnLoading = true
          this.$http({
            url: this.$http.adornUrl(`/admin/virtual/${!this.dataForm.id ? 'saveVirtualUser' : 'updateVirtualUser'}`),
            method: 'post',
            data: this.$http.adornData({
              'uid': this.dataForm.uid,
              ...this.dataForm
            })
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.btnLoading = false
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.btnLoading = false
              this.$message.error(data.msg)
            }
          })
        }
      })
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>